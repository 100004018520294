import { format } from 'date-fns'
import { DateRange } from '@/lib/models/types/Dates'
import { isUndefined, isNull } from 'lodash'

export function parseDateRange(
  dateRange: DateRange | undefined | string[]
): string[] | undefined {
  if (!isDateRangeValid(dateRange)) return undefined

  const datesArray: string[] = []

  if (dateRange.length) {
    datesArray[0] = format(dateRange[0], 'yyy-MM-dd')
    datesArray[1] = format(dateRange[1] || dateRange[0], 'yyy-MM-dd')
  }

  return datesArray
}

export function isDateRangeValid(
  val: DateRange | undefined | string[]
): val is [Date, Date | null] {
  return !isUndefined(val) && !isNull(val[0])
}
