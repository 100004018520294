import { createContext, useContext } from 'react'
import Router from 'next/router'

import { useUser } from '@/lib/api/queries'
import { User } from '@/lib/models/types/User'
import { removeAuthToken, setAuthToken } from '@/lib/storage'
import { AxiosResponse } from 'axios'
import { useLoginMutation } from '../api/mutations/useLoginMutation'
import { LoginFormData } from '../validation/Login'
import jwt_decode from 'jwt-decode'
import { Routes } from '../models/enums/Routes.enum'
import { JwtDecode } from '../models/types'
import { getRedirectRouteByRole } from '../utils/getRedirectRouteByRole'

interface AuthContextData {
  user: User | undefined
  fetchUser: () => void
  login: (data: LoginFormData) => Promise<AxiosResponse>
  logout: () => void
  isLoginLoading: boolean
  isUserLoading: boolean
  isOperator: boolean
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC = ({ children }) => {
  const { user, isUserLoading, fetchUser } = useUser()

  const onSuccess = (response: AxiosResponse) => {
    const token = response.headers['vojo-authorization']
    setAuthToken(token)

    const { permissions }: JwtDecode = jwt_decode(token)

    fetchUser()
    const route = getRedirectRouteByRole(permissions)
    Router.push(route)

    return response
  }

  const [loginApiCall, isLoginLoading] = useLoginMutation()

  const login = (data: LoginFormData) => loginApiCall(data).then(onSuccess)

  const logout = () => {
    removeAuthToken()
    Router.push(Routes.LOGIN)
  }

  const isOperator = user && user.roles.includes('OPERATOR')

  return (
    <AuthContext.Provider
      value={{
        user,
        fetchUser,
        isUserLoading,
        isLoginLoading,
        login,
        logout,
        isOperator: Boolean(isOperator),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextData => useContext(AuthContext)

export default AuthContext
