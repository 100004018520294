import { useLoadingCallback } from '@/lib/hooks'
import { LoginFormData } from '@/lib/validation/Login'
import { AxiosResponse } from 'axios'
import axiosInstance from '../axios'

type Login = (data: LoginFormData) => Promise<AxiosResponse>
export type UseLoginMutation = () => [login: Login, isLoading: boolean]

export const useLoginMutation: UseLoginMutation = () =>
  useLoadingCallback<Login>(data => axiosInstance.post('/v3/auth/login', data))
