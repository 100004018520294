import edukAxiosInstance from '@/lib/api/edukAxios'

export type RequestResetPassword = (params: {
  userId?: string
  onSuccess: (data: string) => void
  onFinally: () => void
}) => void

export const requestResetPassword: RequestResetPassword = ({
  userId,
  onSuccess,
  onFinally,
}) => {
  return {
    data: edukAxiosInstance
      .get(`v1/admin/users/${userId}/request-reset-password`)
      .then(resp => {
        onSuccess(resp.data)
      })
      .finally(onFinally),
  }
}
