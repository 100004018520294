import { User } from '@/lib/models/types'
import useSWR from 'swr'
import { getAuthToken } from '@/lib/storage/authToken'
import axiosInstance from '../axios'

type Response = { data: User }

const userFetcher = () =>
  axiosInstance.get<Response>('/v3/auth/me').then(({ data: res }) => res.data)

export const useUser = (): {
  user: User | undefined
  isUserLoading: boolean
  fetchUser: () => void
} => {
  const key = getAuthToken() ? '/v3/auth/me' : null
  const { data, error, mutate } = useSWR(key, userFetcher)

  return {
    user: data,
    isUserLoading: !error && !data,
    fetchUser: mutate,
  }
}
