import { ToastContainerProps } from 'react-toastify'
import { StyledToastContainer } from './styles'

// More info about react-toastify:
// https://fkhadra.github.io/react-toastify/introduction

const Toaster: React.FC = () => {
  const props: ToastContainerProps = {
    position: 'bottom-center',
    closeOnClick: false,
    newestOnTop: true,
    hideProgressBar: true,
  }

  return <StyledToastContainer {...props} />
}

export default Toaster
