import Cookies from 'js-cookie'

const key = 'authToken'

export const getAuthToken = (): string | undefined => {
  return Cookies.get(key)
}

export const setAuthToken = (authToken: string): void => {
  Cookies.set(key, authToken, { expires: 1 })
}

export const removeAuthToken = (): void => {
  Cookies.remove(key)
}
