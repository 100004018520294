import { ModalitiesExternal, OptionString } from '@/lib/models/types'
import { AxiosError } from 'axios'
import useSWR from 'swr'
import axiosInstance from '../axios'

export type UseModalitiesQuery = (code?: string) => {
  data?: ModalitiesExternal[]
  modalitiesOptions: OptionString[]
  isLoading: boolean
  error: AxiosError
}

const Fetcher = (url: string) =>
  axiosInstance.get<ModalitiesExternal[]>(url).then(resp => resp.data)

export const useModalities: UseModalitiesQuery = () => {
  const route = 'v4/modalities'

  const { data, error } = useSWR(route, Fetcher)

  return {
    data,
    modalitiesOptions: formatModalitiesToOptions(data),
    isLoading: !error && !data,
    error: error?.response?.data.error.message,
  }
}

const formatModalitiesToOptions = (modalities?: ModalitiesExternal[]) => {
  if (!modalities) return []
  return modalities.map(({ id: value, name: label }) => ({ value, label }))
}
