import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`

html, body{
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
}
`
