import { createTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
import { ptBR } from '@material-ui/core/locale'

type CssColor = React.CSSProperties['color']

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    lightGrey2: CssColor
    lightGrey3: CssColor
    darkGrey: CssColor
    darkGrey2: CssColor
    lightGrey: CssColor
    mediumGrey: CssColor
    lateBlack: CssColor
    grey_dark_1: CssColor
    edukRed: CssColor
    lightEdukRed: CssColor
    orange: CssColor
    mediumRed: CssColor
    red: CssColor
    lightRed: CssColor
    green: CssColor
  }
  // allow configuration using createTheme
  interface ThemeOptions {
    grey_dark_1?: CssColor
    lightGrey2?: CssColor
    lightGrey3?: CssColor
    darkGrey?: CssColor
    darkGrey2?: CssColor
    lightGrey?: CssColor
    mediumGrey?: CssColor
    lateBlack?: CssColor
    edukRed?: CssColor
    lightEdukRed: CssColor
    orange: CssColor
    mediumRed?: CssColor
    red?: CssColor
    lightRed?: CssColor
    green: CssColor
    silver?: CssColor
    neutralBlue?: CssColor
  }
}

// Create a theme instance.
const theme = createTheme(
  {
    lightGrey2: '#DDDDDD',
    lightGrey3: '#F4F4F4',
    darkGrey: '#4F4F4F',
    darkGrey2: '#9B9B9B',
    lightGrey: '#BDBDBD',
    mediumGrey: '#828282',
    silver: '#D3D3D3',
    lateBlack: '#333333',
    grey_dark_1: '#9E9E9E',
    edukRed: '#FF0033',
    lightEdukRed: '#FF003314',
    orange: '#F2994A',
    mediumRed: '#EB5757',
    red: '#E10000',
    lightRed: '#EB57574D',
    green: '#219653',
    neutralBlue: '#2687FB',
    palette: {
      primary: {
        main: '#FF0033',
      },
      secondary: {
        main: '#2687FB',
      },
      error: {
        main: red.A400,
      },
      background: {
        default: '#fff',
      },
    },
    overrides: {
      MuiTablePagination: {
        input: { marginRight: 'auto' },
        root: { width: '100%' },
        spacer: { flex: 'none' },
      },
    },
  },
  ptBR
)

export default theme
