export enum Routes {
  ACCESSES_CREATE = '/accesses/create',
  CANDIDATES = '/candidates',
  CHANGE_PASSWORD = '/change-password',
  COMPANIES = '/companies',
  COMPANIES_CANDIDATES = '/companies/candidates',
  ACCESSES = '/accesses',
  COMPANIES_CREATE = '/companies/create',
  COMPANIES_EDIT = '/companies/edit',
  COMPANIES_JOBROLES = '/companies/job-roles',
  DASHBOARD = '/dashboard',
  JOBROLES = '/job-roles',
  JOBROLES_CREATE = '/job-roles/create',
  JOBROLES_EDIT = 'job-roles/edit',
  LOGIN = '/login',
  NOTIFICATIONS = '/notifications',
  PASSWORD_RECOVERY_ROUTE = '/password-recovery',
  PASSWORD_RECOVERY_SUCESS_ROUTE = '/password-recovery/success',
  USERS = '/users',
  OI_REGISTER_USER = '/oi-register-user',
  BENEFITS = '/benefits',
}
