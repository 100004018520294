import { useCompany } from '@/lib/api/queries/useCompany'
import { Routes } from '../models/enums/Routes.enum'

export type Link = {
  text: string
  url: string
  onClick?: () => void
}

export type UseBreadCrumbHistory = () => {
  createCompanyLink: (companyId: string) => Promise<Link>
  createCandidateLink: (slug: string, title?: string) => Link
  createUserLink: (userId: string) => Link
}

export const useBreadCrumbHistory: UseBreadCrumbHistory = () => {
  const { manualRequest: getCompany } = useCompany()

  const createCompanyLink = (companyId: string) =>
    getCompany(companyId).then(({ data }) => ({
      text: `${data.socialName}`,
      url: `${Routes.COMPANIES_JOBROLES}/${data.id}`,
    }))

  const createCandidateLink = (slug: string, title?: string) => ({
    text: `${title}`,
    url: `${Routes.CANDIDATES}/${slug}`,
  })

  const createUserLink = (userId: string) => ({
    text: userId,
    url: `${Routes.USERS}/${userId}`,
  })

  return {
    createCompanyLink,
    createCandidateLink,
    createUserLink,
  }
}
