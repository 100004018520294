import useSWR from 'swr'
import axiosInstance from '../axios'
import { AxiosError } from 'axios'
import { OptionString } from '@/lib/models/types'

type Category = {
  name: string
  id: string
}

const fetcher = (url: string) =>
  axiosInstance.get<Category[]>(url).then(resp => resp.data)

const formatCategoriesToOptions = (
  categories: Category[],
  type: 'vojo' | 'eduk'
) => {
  return categories.map(({ name, id }) => {
    const value = type === 'vojo' ? name : id
    return { value, label: name }
  })
}

export const useCategories = (
  type: 'vojo' | 'eduk'
): {
  data: Category[] | undefined
  options: OptionString[]
  isLoading: boolean
  error: AxiosError
} => {
  const route = `/categories?type=${type}`
  const { data, error } = useSWR(route, fetcher)

  return {
    data,
    options: formatCategoriesToOptions(data || [], type),
    isLoading: !error && !data,
    error,
  }
}
