import { useState, ChangeEvent } from 'react'

export type UseInput = (initialValue: string) => {
  value: string
  setValue: (val: string) => void
  bind: {
    value: string
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
  }
}

export const useInput: UseInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue)

  const onChange = (event: ChangeEvent<HTMLInputElement>) =>
    setValue(event.currentTarget.value)

  return { value, setValue, bind: { value, onChange } }
}
