import { GlobalStyle } from '@/styles/globals'
import { AppPropsWithLayout } from '@/lib/models/types/layouts'
import Head from 'next/head'

import { AuthProvider } from '@/lib/context/AuthContext'
import { BreadCrumbProvider } from '@/lib/context/BreadCrumbContext'

import Toaster from '@/components/Toaster'

import theme from '@/styles/theme'
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core'
import { StylesProvider as MuiStylesProvider } from '@material-ui/core/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { useInitAmplitude, useMuiSsr, useCookieNotice } from '@/lib/hooks'
import { initFirebase } from '@/lib/services/firebase/initFirebase'

const MyApp: React.FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  useMuiSsr()
  useCookieNotice()
  useInitAmplitude()
  initFirebase()

  return (
    <>
      <Head>
        <title>Painel Eduk Empresas</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <StyledThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <MuiStylesProvider injectFirst>
            <CssBaseline />
            <GlobalStyle />
            <AuthProvider>
              <BreadCrumbProvider>
                {Component.Layout ? (
                  <Component.Layout>
                    <Component {...pageProps} />
                  </Component.Layout>
                ) : (
                  <Component {...pageProps} />
                )}
              </BreadCrumbProvider>
            </AuthProvider>
            <Toaster />
          </MuiStylesProvider>
        </MuiThemeProvider>
      </StyledThemeProvider>
    </>
  )
}

export default MyApp
