import useSWR from 'swr'
import axiosInstance from '../axios'
import { createQueryString } from '@/lib/utils/createQueryString'
import { useAuth } from '@/lib/context/AuthContext'
import { AxiosError } from 'axios'
import { mutateMany } from 'swr-mutate-many'
import { PaginatedResponse, JobRole, QueryPagination } from '@/lib/models/types'
import { useRouter } from 'next/router'

type PaginatedJobRoles = PaginatedResponse<JobRole>

const jobRolesFetcher = (url: string) =>
  axiosInstance.get<PaginatedJobRoles>(url).then(resp => resp.data)

type JobRoleQueryParams = { title?: string } & QueryPagination

export type UseJobRoles = (params: JobRoleQueryParams) => {
  paginatedJobRoles: PaginatedJobRoles | undefined
  isTableLoading: boolean
  tableError: AxiosError<PaginatedJobRoles>
  revalidateTable: () => void
}

export const useJobRoles: UseJobRoles = params => {
  const { user, isOperator } = useAuth()
  const queryCompanyId = useRouter().query.id

  const companyId = queryCompanyId || user?.companyId

  // If the user has an operator permission and is not on
  // a company route, the request must return all jobRoles.
  // Otherwise it must return jobRoles for a specific company.
  const route =
    isOperator && !queryCompanyId
      ? `/v3/admin/jobroles?`
      : `/v3/companies/${companyId}/jobroles?`

  const key =
    !isOperator && !companyId
      ? null
      : `${route}${createQueryString(treatParams(params))}`

  const { data, error } = useSWR(key, jobRolesFetcher)

  const revalidateTable = () => mutateMany(`${route}*`, null, true)

  return {
    paginatedJobRoles: data,
    isTableLoading: !error && !data,
    tableError: error,
    revalidateTable,
  }
}

const defaultParams = {
  applicationStatus: ['WORKING', 'SELECTED', 'READY', 'DISQUALIFIED'],
}
const treatParams = (params: JobRoleQueryParams) => ({
  applicationStatus: defaultParams.applicationStatus,
  ...params,
})
