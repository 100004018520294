import { createContext, useContext, useState } from 'react'
import { useBreadCrumbHistory, Link } from '../../hooks/useBreadCrumbHistory'
import { useRouter } from 'next/router'
import { Routes } from '@/lib/models/enums/Routes.enum'

type LinkType = 'company' | 'candidate' | 'user'

interface BreadCrumbContextData {
  history: Link[]
  pushRoute: (param: string, linkType: LinkType, title?: string) => void
  clearBreadCrumbHistory: () => void
}

const BreadCrumbContext = createContext<BreadCrumbContextData>({
  history: [],
  pushRoute: () => null,
  clearBreadCrumbHistory: () => null,
})

export const BreadCrumbProvider: React.FC = ({ children }) => {
  const [history, setHistory] = useState<Link[]>([])
  const { pathname } = useRouter()
  const { createCompanyLink, createCandidateLink, createUserLink } =
    useBreadCrumbHistory()

  const firstLink = () => {
    if (pathname.includes('companies'))
      return {
        text: 'Empresas',
        url: Routes.COMPANIES,
        onClick: () => setHistory([]),
      }

    if (pathname.includes('users'))
      return {
        text: 'Usuários',
        url: Routes.USERS,
        onClick: () => setHistory([]),
      }

    return {
      text: 'Vagas',
      url: Routes.JOBROLES,
      onClick: () => setHistory([]),
    }
  }

  const pushRoute = async (
    slug: string,
    linkType: LinkType,
    title?: string
  ) => {
    // If the list is empty, add the first link
    // otherwise copy the existing history
    const newHistory = history.length ? [...history] : [firstLink()]

    const link = await createLink[linkType](slug, title)

    if (link) newHistory.push(link)

    // If there is the possibility of go back to an intermediate
    // link (more than 2 links in history), it adds an onCick
    // function which removes successor elements
    if (newHistory.length > 2) return setHistory(createOnClick(newHistory))

    setHistory(newHistory)
  }

  const clearBreadCrumbHistory = () => {
    setHistory([])
  }

  // choose the request that will be used to assemble the link
  const createLink = {
    candidate: (param: string, title?: string) =>
      createCandidateLink(param, title),
    company: (param: string) => createCompanyLink(param),
    user: (param: string) => createUserLink(param),
  }

  /**
   * @description adds an onClick function for each element,
   * which removes successor elements from the clicked element
   */
  const createOnClick = (links: Link[]) => {
    const newHistory = [...links]

    for (let index = 1; index < history.length; index++) {
      const elementsToRemove = links.length - index
      newHistory[index].onClick = () => handleGoBack(elementsToRemove)
    }
    return newHistory
  }

  /**
   * @description removes successor elements from the clicked element
   */
  const handleGoBack = (elementsToRemove: number) => {
    const newHistory: Link[] = [...history].slice(-elementsToRemove)
    if (newHistory) setHistory(newHistory)
  }

  return (
    <BreadCrumbContext.Provider
      value={{
        history,
        pushRoute,
        clearBreadCrumbHistory,
      }}
    >
      {children}
    </BreadCrumbContext.Provider>
  )
}

export const useBreadCrumb = (): BreadCrumbContextData =>
  useContext(BreadCrumbContext)

export default BreadCrumbContext
