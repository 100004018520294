import { useEffect } from 'react'

import { toast } from 'react-toastify'
import { getCookieConsent, setCookieConsent } from '@/lib/storage'

const message =
  'Esta aplicação precisa utilizar cookies para funcionar. Ao usar a aplicação você está consentindo ao uso de cookies.'

// This hook shows a banner on mount, notifying the user that the app uses cookies.
export const useCookieNotice = (): void => {
  useEffect(() => {
    if (getCookieConsent()) return

    toast.info(message, {
      onClose: () => setCookieConsent(true),
      autoClose: false,
      draggable: false,
    })
  }, [])
}
