import { DateRange } from '@/lib/models/types'

/**
 *
 * @param params Object containing query parameters.
 * Ex: {limit: 2, userName: "paulo"}
 * @returns String containint query parameters. Ex:limit=2&userName=paulo
 */
export type CreateQueryString = (params: {
  [key: string]: string | number | boolean | undefined | string[] | DateRange
}) => string

export const createQueryString: CreateQueryString = params => {
  return Object.keys(params)
    .map(key => {
      const value = params[key]
      if (Array.isArray(value) && value.length > 0) {
        return (
          encodeURIComponent(key) +
          '=' +
          encodeURIComponent(value.join(',')) +
          '&'
        )
      }
      if (value !== undefined && value !== '' && !Array.isArray(value))
        return encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&'
    })
    .join('')
    .slice(0, -1)
}
