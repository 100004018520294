import useSWR from 'swr'
import axiosInstance from '../axios'
import { AxiosError } from 'axios'

export type UseAllCompanies = () => {
  companies: Company[] | undefined
  isLoading: boolean
  error: AxiosError
}

export type Company = {
  legalName: string
  socialName: string
  cnpj: string
  id: string
}

const AllCompaniesFetcher = (url: string) =>
  axiosInstance.get<{ data: Company[] }>(url).then(resp => resp.data)

export const useAllCompanies: UseAllCompanies = () => {
  const key = 'v1/backoffice/companies'
  const { data, error } = useSWR(key, AllCompaniesFetcher)

  return {
    companies: data?.data,
    isLoading: !error && !data,
    error: error?.response?.data.error.message,
  }
}
