import useSWR from 'swr'
import axiosInstance from '../axios'
import { JobRole } from '@/lib/models/types'
import { AxiosResponse, AxiosError } from 'axios'

const jobRoleBySlugFetcher = (url: string) =>
  axiosInstance.get<{ data: JobRole }>(url).then(resp => resp.data)

export const useJobRoleBySlug = (
  slug?: string
): {
  jobRole: JobRole | undefined
  isJobRoleLoading: boolean
  error: AxiosError<{ data: JobRole }>
  manualRequest: (
    companyId: string
  ) => Promise<AxiosResponse<{ data: JobRole }>>
} => {
  const route = `/v3/jobroles/${slug}?`

  const manualRequest = (slug: string) =>
    axiosInstance.get<{ data: JobRole }>(`/v3/jobroles/${slug}?`)

  const { data, error } = useSWR(slug ? route : null, jobRoleBySlugFetcher)

  return {
    jobRole: data?.data,
    isJobRoleLoading: !error && !data,
    manualRequest,
    error: error?.response?.data.error.message,
  }
}
