import { useState, useCallback } from 'react'

type UseToggleTuple = [value: boolean, toggle: () => void]

export default function useToggle(initialValue = false): UseToggleTuple {
  const [value, setValue] = useState(initialValue)

  const toggle = useCallback(() => setValue(val => !val), [])

  return [value, toggle]
}
