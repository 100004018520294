import axios from 'axios'
import debounce from 'lodash/debounce'
import Router from 'next/router'

import { getAuthToken, removeAuthToken } from '@/lib/storage/authToken'

import { toast } from 'react-toastify'
import { Routes } from '../models/enums/Routes.enum'
const debouncedToast = debounce(toast.error)

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_API,
  headers: {
    'Content-Type': 'application/json',
  },
})

// REQUEST INTERCEPTORS
axiosInstance.interceptors.request.use(config => {
  const authToken = getAuthToken()
  if (authToken) config.headers.Authorization = `Bearer ${authToken}`

  return config
})

// RESPONSE INTERCEPTORS
axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401 && Router.asPath !== Routes.LOGIN) {
      Router.push(Routes.LOGIN)
      removeAuthToken()

      debouncedToast('Seu token está inválido. Por favor, faça login novamente')
    } else {
      throw error
    }
  }
)

export default axiosInstance
