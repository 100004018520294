import styled, { css } from 'styled-components'
import { ToastContainer } from 'react-toastify'

// The library instructs us to download this .css
// https://fkhadra.github.io/react-toastify/installation
import 'react-toastify/dist/ReactToastify.css'

export const StyledToastContainer = styled(ToastContainer)(props => {
  const {
    theme: { darkGrey },
  } = props

  return css`
    --toastify-color-progress-light: white;
    --toastify-text-color-light: white;
    --toastify-color-light: ${darkGrey};
    --toastify-toast-width: clamp(300px, 50em, 33vw);

    .Toastify__toast-body {
      justify-content: center;
    }
  `
})
