import {
  FormatAssesment,
  FormatApplicationFormFields,
  FormatApplicationForm,
  FormatReadyViewDetail,
  FormatLocations,
  FormatTextField,
  FormatCities,
  FormatRequirements,
  FormatCategories,
  FormatModalities,
} from './types'

// JobRole Detail
export const formatLocations: FormatLocations = locations => {
  if (!locations) return []
  return locations.map(location => ({
    state: location.state.initials,
    cities: formatCities(location.cities),
  }))
}

export const formatCities: FormatCities = cities => {
  return cities.map(city => ({ value: city, label: city, __isNew__: false }))
}

export const formatTextField: FormatTextField = textField => {
  return textField?.join('\n') || ''
}

export const formatRequirements: FormatRequirements = requirements => {
  if (!requirements) return []
  return requirements.map((requirement, index) => ({
    value: requirement,
    id: `requirement-${index}`,
  }))
}

export const formatCategories: FormatCategories = categories => {
  if (!categories || categories.length < 1) return

  return categories[0]._id
}

export const formatModalities: FormatModalities = modalities => {
  if (!modalities || modalities.length < 1) return

  return modalities.map(({ _id: value, name: label }) => ({ value, label }))
}

// Process Detail
export const formatAssesment: FormatAssesment = assessment => {
  return {
    enable: assessment?.active || false,
    testCode: assessment?.typeformId,
    passingScore: assessment?.settings?.passingScore,
    methodWeights: assessment?.settings?.methodWeights,
  }
}

export const formatApplicationForm: FormatApplicationForm = data => {
  if (!data) return {}
  const { enable, fields } = data

  return {
    enable,
    fields: formatApplicationFormFields(fields),
  }
}

export const formatApplicationFormFields: FormatApplicationFormFields =
  fields => {
    if (!fields) return []

    return fields.map(({ name, label, required }) => ({
      name,
      label: label || name,
      value: required,
    }))
  }

// Ready View Detail
export const formatReadyViewDetail: FormatReadyViewDetail = data => {
  if (!data) return {}
  const { settings, type } = data

  return {
    type: type || 'vojo-redirect',
    url: settings?.url,
    buttonText: settings?.buttonText,
    textField: formatTextField(settings?.textField),
  }
}
