import useSWR from 'swr'
import axiosInstance from '../axios'
import { AxiosError } from 'axios'
import { mutateMany } from 'swr-mutate-many'
import { ReadyViewDetail, ReadyViewDetailModel } from '@/lib/models/types'

type ReadyViewDetailResponse = {
  type: ReadyViewDetailModel
  settings: {
    textField: string[]
    buttonText: string
    url: string
  }
}

const Fetcher = (url: string) =>
  axiosInstance.get<ReadyViewDetailResponse>(url).then(resp => resp.data)

const url = (modelType: ReadyViewDetailModel, jobRoleId?: string) =>
  `/configuration/page/ready-view/${modelType}${
    jobRoleId ? `?jobRoleId=${jobRoleId}` : ''
  }`

export type UseReadyViewDetail = (
  jobRoleId?: string,
  model?: ReadyViewDetailModel
) => {
  data: ReadyViewDetail | undefined
  isLoading: boolean
  error: AxiosError
  revalidate: () => void
  manualRequest: (modelType: ReadyViewDetailModel) => Promise<ReadyViewDetail>
}

export const useReadyViewDetail: UseReadyViewDetail = (jobRoleId, model) => {
  const route = !model ? null : url(model, jobRoleId)

  const { data, error } = useSWR(route, Fetcher)

  const revalidate = () => mutateMany(`${route}*`, null, true)

  return {
    data: formatData(data),
    isLoading: !error && !data,
    error,
    revalidate,
    manualRequest: (modelType: ReadyViewDetailModel) =>
      Fetcher(url(modelType, jobRoleId)).then(data => formatData(data)),
  }
}

function formatData(data?: ReadyViewDetailResponse): ReadyViewDetail {
  if (!data) return {}
  const { url, textField, buttonText } = data.settings

  return {
    url,
    buttonText,
    textField: formatTextField(textField),
    type: data.type,
  }
}

function formatTextField(textField: string[]): string {
  return textField?.join('\n') || ''
}
