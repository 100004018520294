import { AxiosResponse } from 'axios'
import useSWR from 'swr'
import axiosInstance from '../axios'

export type Company = {
  id: string
  active: boolean
  legalName: string
  socialName: string
  logo: string
  biDashboard: string
  cnpj: string
}

const CompanyFetcher = (url: string) =>
  axiosInstance.get<Company>(url).then(resp => resp.data)

export const useCompany = (
  companyId?: string
): {
  company: Company | undefined
  isLoading: boolean
  manualRequest: (companyId: string) => Promise<AxiosResponse<Company>>
} => {
  const key = companyId ? `/v3/companies/${companyId}` : null
  const { data, error } = useSWR(companyId ? key : null, CompanyFetcher)

  const manualRequest = (companyId: string) =>
    axiosInstance.get<Company>(`/v3/companies/${companyId}`)

  return {
    company: data,
    isLoading: !error && !data,
    manualRequest,
  }
}
