import useSWR from 'swr'
import axiosInstance from '../axios'
import { AxiosError } from 'axios'
import { FieldOptions } from '@/lib/models/types'

export type CompanyFieldsResponse = FieldResponse[]

const companyFieldsFetcher = (url: string) =>
  axiosInstance.get<CompanyFieldsResponse>(url).then(resp => resp.data)

export type UseCompanyFields = (companyId?: string) => {
  fields: FieldResponse[]
  isLoading: boolean
  error: AxiosError<CompanyFieldsResponse>
  manualRequest: (companyId: string) => Promise<CompanyFieldsResponse>
}

export type FieldResponse = {
  active: boolean
  name: string
  title: string
  id: string
} & Record<string, unknown>

export const useCompanyFields: UseCompanyFields = companyId => {
  const key = companyId ? `/v3/admin/fields/companies/${companyId}` : null

  const { data, error } = useSWR(companyId ? key : null, companyFieldsFetcher)

  const manualRequest = (companyId: string) =>
    axiosInstance
      .get<CompanyFieldsResponse>(`/v3/admin/fields/companies/${companyId}`)
      .then(resp => resp.data)

  return {
    fields: data || [],
    isLoading: !error && !data,
    error,
    manualRequest,
  }
}

export function formatFieldOptions(fields: FieldResponse[]): FieldOptions[] {
  if (!fields) return []
  return fields.map(({ title, name }) => ({
    name,
    value: name,
    label: title || name,
  }))
}
