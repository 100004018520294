import useSWR from 'swr'
import axiosInstance from '../../axios'
import { createQueryString } from '@/lib/utils/createQueryString'
import { useAuth } from '@/lib/context/AuthContext'
import { AxiosError } from 'axios'
import { mutateMany } from 'swr-mutate-many'
import {
  PaginatedResponse,
  Candidate,
  QueryPagination,
  CandidateStatus,
} from '@/lib/models/types'
import { DateRange } from '@/lib/models/types/Dates'
import { useRouter } from 'next/router'
import { parseDateRange } from './utils'

export type PaginatedCandidates = PaginatedResponse<Candidate>

const CandidatesFetcher = (url: string) =>
  axiosInstance.get<PaginatedCandidates>(url).then(resp => resp.data)

type CandidatesQueryParams = {
  jobRoleIds?: string[] | string
  userName?: string
  cities?: string[]
  status?: CandidateStatus[]
  statusChangedAt?: DateRange
} & QueryPagination

export type UseCandidates = (params: CandidatesQueryParams) => {
  paginatedCandidates: PaginatedCandidates | undefined
  isTableLoading: boolean
  tableError: AxiosError<PaginatedCandidates>
  revalidateTable: () => void
}

export const useCandidatesQuery: UseCandidates = params => {
  const { user, isOperator } = useAuth()
  const queryCompanyId = useRouter().query.id

  const companyId = queryCompanyId || user?.companyId

  // If the user has an operator permission and is not on
  // a company route, the request must return all applications.
  // Otherwise it must return applications for a specific company.
  const route =
    isOperator && !queryCompanyId
      ? `/v3/admin/applications?`
      : `/v3/admin/companies/${companyId}/applications?`

  const key =
    !isOperator && !companyId
      ? null
      : `${route}${createQueryString(treatParams(params))}`

  const { data: paginatedCandidates, error } = useSWR(
    // Query shouldn't run if user isn't logged in
    key,
    CandidatesFetcher
  )

  const revalidateTable = () => mutateMany(`${route}*`, null, true)

  return {
    paginatedCandidates,
    isTableLoading: !error && !paginatedCandidates,
    tableError: error,
    revalidateTable,
  }
}

type CandidateQueryStringParams = Omit<
  CandidatesQueryParams,
  'statusChangedAt'
> & {
  statusChangedAt?: string[]
}

const defaultParams: Partial<CandidatesQueryParams> = {
  status: [
    'WORKING',
    'SELECTED',
    'READY',
    'DISQUALIFIED',
    'STAND_BY',
    'DEACTIVATED',
  ],
}

type TreatParams = (params: CandidatesQueryParams) => CandidateQueryStringParams

const treatParams: TreatParams = params => ({
  ...params,
  status: params.status?.length ? params.status : defaultParams.status,
  statusChangedAt: parseDateRange(params.statusChangedAt),
})
