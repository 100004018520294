import { useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AsyncCallback = (...args: any) => Promise<unknown>

/**
 *
 * @param callback An async callback function
 * @returns [LoadingCallback, isLoadiing] A function to run the callback and a
 * boolean indicating if it is in progress.
 */
export const useLoadingCallback = <T extends AsyncCallback>(
  callback: T
): [loadingCallback: T, isLoading: boolean] => {
  const [isLoading, setIsLoading] = useState(false)

  const handleCallback = async (...args: Parameters<T>) => {
    setIsLoading(true)

    try {
      return await callback(...args)
    } catch (e) {
      throw e
    } finally {
      setIsLoading(false)
    }
  }

  return [handleCallback as T, isLoading]
}
