import useSWR from 'swr'
import axiosInstance from '../../axios'
import { AxiosError } from 'axios'
import {
  JobRoleContextData,
  JobRoleDetailContext,
  ProcessDetailContext,
} from '@/lib/models/types'
import { EditJobRoleResponse, ReadyViewDetailResponse } from './types'
import {
  formatRequirements,
  formatLocations,
  formatReadyViewDetail,
  formatAssesment,
  formatApplicationForm,
  formatCategories,
  formatModalities,
} from './utils'

const editJobRoleFetcher = (url: string) =>
  axiosInstance.get<EditJobRoleResponse>(url).then(resp => resp.data)

export type UseEditJobRole = (jobRoleId?: string) => {
  initialData: Partial<JobRoleContextData>
  isLoading: boolean
  error: AxiosError<EditJobRoleResponse>
  invalidateInitialData: () => void
}

export const useEditJobRoleQuery: UseEditJobRole = jobRoleId => {
  const route = `/v3/admin/jobroles/${jobRoleId}`

  const { data, error, mutate } = useSWR(
    jobRoleId ? route : null,
    editJobRoleFetcher
  )

  const jobRoleDetail: JobRoleDetailContext = {
    title: data?.title,
    description: data?.description,
    modalities: formatModalities(data?.modalities),
    area: data?.area,
    categories: formatCategories(data?.categories),
    requirements: formatRequirements(data?.requirements),
    locations: formatLocations(data?.jobLocations),
  }

  const readyViewDetail: ReadyViewDetailResponse = formatReadyViewDetail(
    data?.readyViewDetail
  )

  const processDetail: ProcessDetailContext = {
    gatekeeperAssessment: formatAssesment(data?.gatekeeperAssessment),
    applicationForm: formatApplicationForm(data?.applicationForm),
    situationalAssessment: formatAssesment(data?.situationalAssessment),
    companyAssessment: formatAssesment(data?.companyAssessment),
  }

  return {
    initialData: data ? { jobRoleDetail, processDetail, readyViewDetail } : {},
    isLoading: !error && !data,
    error,
    invalidateInitialData: mutate,
  }
}
